import { defineStore } from 'pinia';
import { useApiConsumer } from '~/composables/useApiConsumer';
import type ItineraryResource from '~/api/resource/ItineraryResource';
import type { TravelingType } from '~/api/enum/TravelingType';
import type ItineraryPointResource from '~/api/resource/ItineraryPointResource';
import type CanInsertTransportRequestResource from '~/api/resource/CanInsertTransportRequestResource';
import type ItineraryPayload from '~/api/payload/ItineraryPayload';
import type CanMoveItineraryPointResource from '~/api/resource/CanMoveItineraryPointResource';
import { ItineraryStatusEnum } from '~/api/enum/ItineraryStatusEnum';
import type { Day } from '~/api/enum/Day';
import type DuplicateItineraryDayPayload from '~/api/payload/DuplicateItineraryDayPayload';

export const useItineraryStore = defineStore('itinerary', () => {
  const client = useApiConsumer();

  const itineraries = ref<ItineraryResource[]>([]);
  const currentItinerary = ref<ItineraryResource | null>(null);
  const isItineraryOnDuplicating = ref<boolean>(false);

  async function populate(startDate: string, endDate: string, travelingType: TravelingType | null) {
    itineraries.value = await client.extractResultFromPaginatedResponse('/itineraries', {
      params: {
        'startDate[after]': startDate,
        'startDate[before]': endDate,
        travelingType: travelingType ? [travelingType] : []
      }
    });

    updateIsItineraryOnDuplicating();
  }

  function updateIsItineraryOnDuplicating() {
    isItineraryOnDuplicating.value = itineraries.value.some((itinerary) => itinerary.status != ItineraryStatusEnum.UPDATED);
  }

  async function deleteItineraryPoint(idItineraryPoint: number) {
    await client.$delete('/itinerary_points/' + idItineraryPoint.toString());
  }

  async function deleteItinerary(itineraryId: number) {
    await client.$delete('/itineraries/' + itineraryId.toString());
  }

  async function deleteItinerariesByWeek(weekNumber: string) {
    await client.$post('/itineraries/delete-week', {
      week: weekNumber
    });
  }

  async function canInsertTransportRequest(idItinerary: number, idTransportRequest: number) {
    return await client.$post<CanInsertTransportRequestResource>(
      '/itineraries/' + idItinerary.toString() + '/transport_request/' + idTransportRequest.toString() + '/can-insert',
      {}
    );
  }

  async function canMoveItineraryPoint(itineraryId: number, pointToMove: number, targetPointId: number) {
    return await client.$post<CanMoveItineraryPointResource>(
      '/itineraries/' + itineraryId.toString() + '/points/' + pointToMove.toString() + '/can-move-after/' + targetPointId.toString(),
      {}
    );
  }

  async function moveItineraryPoint(itineraryId: number, pointToMove: number, targetPointId: number, idVehicleConfiguration?: number) {
    let body = {};
    if (idVehicleConfiguration) {
      body = { vehicleConfigurationToUse: '/api/vehicle_configurations/' + idVehicleConfiguration };
    }

    return await client.$post<CanMoveItineraryPointResource>(
      '/itineraries/' + itineraryId.toString() + '/points/' + pointToMove.toString() + '/move-after/' + targetPointId.toString(),
      body
    );
  }

  async function insertTransportRequest(idItinerary: number, idTransportRequest: number, idVehicleConfiguration?: number) {
    let body = {};

    if (idVehicleConfiguration) {
      body = { vehicleConfigurationToUse: '/api/vehicle_configurations/' + idVehicleConfiguration };
    }

    return await client.$post<CanInsertTransportRequestResource>(
      '/itineraries/' + idItinerary.toString() + '/transport_request/' + idTransportRequest.toString() + '/insert',
      body
    );
  }

  async function createItinerary(itineraryPayload: ItineraryPayload): Promise<ItineraryResource> {
    return await client.$post<ItineraryResource>('/itineraries', itineraryPayload);
  }

  async function updateItinerary(id: number, itineraryPayload: ItineraryPayload): Promise<ItineraryResource> {
    return await client.$patch<ItineraryResource>('/itineraries/' + id.toString(), itineraryPayload);
  }

  async function duplicateItineraryDay(duplicateItineraryDayPayload: DuplicateItineraryDayPayload) {
    return await client.$post<ItineraryResource>('/itineraries/duplicate-days', duplicateItineraryDayPayload);
  }

  async function exportItineraries(startDate: string, endDate: string) {
    return client
      .$get('/export-itinerary', {
        params: {
          startDate: startDate,
          endDate: endDate
        },
        responseType: 'blob'
      })
      .then((response) => {
        const blobResponse = response as BlobPart;
        const url = window.URL.createObjectURL(new Blob([blobResponse]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.xls');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  }

  async function populateCurrentItinerary(id: number) {
    const itinerary: ItineraryResource = (await client.$get('/itineraries/' + id.toString())) as ItineraryResource;
    if (itinerary) {
      itinerary.itineraryPoints = await client.extractResultFromPaginatedResponse('/itineraries/' + id + '/points');
      currentItinerary.value = itinerary;
    }
    const index = itineraries.value.findIndex((item) => item.id === id);
    if (index !== -1) {
      itineraries.value[index] = itinerary;
    } else {
      itineraries.value.push(itinerary);
    }

    updateIsItineraryOnDuplicating();
  }

  async function populateWithPoint(startDate: string, endDate: string, travelingType: TravelingType | null) {
    const currentItineraries: ItineraryResource[] = await client.extractResultFromPaginatedResponse('/itineraries', {
      params: {
        'startDate[after]': startDate,
        'startDate[strictly_before]': endDate,
        travelingType: travelingType ? [travelingType] : []
      }
    });

    const promises = currentItineraries.map((itinerary) => client.extractResultFromPaginatedResponse('/itineraries/' + itinerary.id + '/points'));

    const itineraryPoints: ItineraryPointResource[][] = (await Promise.all(promises)) as ItineraryPointResource[][];

    for (let i = 0; i < currentItineraries.length; i++) {
      currentItineraries[i].itineraryPoints = itineraryPoints[i];
    }
    itineraries.value = currentItineraries;

    updateIsItineraryOnDuplicating();
  }

  function $reset() {
    currentItinerary.value = null;
    isItineraryOnDuplicating.value = false;
    itineraries.value = [];
  }

  return {
    currentItinerary,
    itineraries,
    populate,
    populateWithPoint,
    populateCurrentItinerary,
    deleteItineraryPoint,
    canInsertTransportRequest,
    insertTransportRequest,
    createItinerary,
    updateItinerary,
    deleteItinerary,
    exportItineraries,
    canMoveItineraryPoint,
    moveItineraryPoint,
    deleteItinerariesByWeek,
    isItineraryOnDuplicating,
    duplicateItineraryDay,
    $reset
  };
});
